@use "../../../abstracts/helpers" as *;

/**
 * In-article advertisement
 */
.ad-container {
    display: flex;
    align-content: stretch;
    align-items: stretch;
    width: 335px;
    height: 255px;
    margin: 32px auto 32px auto;

    img {
        object-fit: cover;
        flex: 1;
    }
}

/**
 * Advertisement container in sidebars
 */
.ad-container--small {
    display: flex;
    align-content: stretch;
    align-items: stretch;
    width: 300px;
    height: 250px;

    img {
        object-fit: cover;
        flex: 1;
    }
}

.ad-container--short {
    margin-top: -2px;
    height: 244px;
    margin-bottom: 24px;
}

.ad-container--tall {
    height: 255px;
}

@include breakpoint(md, "only screen") {

    .ad-container {
        width: 100%;
        max-width: 632px;
        height: 478px;
        margin: 32px auto 32px auto;
        background: none;
        mix-blend-mode: normal;
        padding-top: 0;

        img {
            background: none;
            mix-blend-mode: normal;
        }
    }

    .ad-container--short {
        margin-top: 32px;
        height: 405px;
        margin-bottom: 32px;
    }

    .ad-container--tall {
        height: 478px;
    }
}