@use "../../abstracts/helpers" as *;

/**
 * Header banner only on bigger screens
 */

.header-banner-container {
    display: none;
}

// This modificator can be used from 1024px, when only one
// banner image is set
@include breakpoint(md, "screen") {
    .header-banner-container--show-on-tablet {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        gap: 32px;

        // This part is the same at all "banner container" link
        a {
            display: flex;
            align-content: stretch;
            align-items: stretch;
            overflow: hidden;

            // All image behaviors are the same
            img {
                object-fit: cover;
                flex: 1;
            }
        }

        .header-banner-container--small__big-banner {
            //width: 964px;
            //height: 250px;
        }
    }
}

// 1366px
@include breakpoint(md, "screen") {
    .header-banner-container {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        gap: 32px;

        // This part is the same at all "banner container" link
        a {
            display: flex;
            align-content: stretch;
            align-items: stretch;
            overflow: hidden;

            // All image behaviors are the same
            img {
                object-fit: cover;
                flex: 1;
            }
        }

        .header-banner-container--small__big-banner {
            width: 964px;
            height: 250px;
            flex-shrink: 0;
        }

        .header-banner-container--small__small-banner {
            width: 300px;
            height: 250px;
            flex-shrink: 0;
        }

        .header-banner-container--big__big-banner {
            width: 964px;
            height: 500px;
        }

        .header-banner-container--big__right-helper {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 300px;

            .header-banner-container--big__small-banner {
                width: 300px;
                height: 250px;
            }

            .header-banner-container--big__small-banner:last-of-type {
                width: 300px;
                // Height 500px - first small banner 250px - 32px gap
                height: 218px;
            }
        }
    }
}