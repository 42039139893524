@use "../../../abstracts/helpers" as *;

.article-header {
    width: 100%;

    h1 {
        margin-bottom: 8px;
    }

    h3 {
        margin-bottom: 24px;
    }

    // When the promo label will be shown, the container should be
    // positioned relative, otherwise this setting has no affect
    .featured-image-container {
        position: relative;
    }
}

@include breakpoint(md, "only screen") {
    
    .article-header {

        h3 {
            margin-bottom: 16px;
        }
    }
}