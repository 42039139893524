/**
 * Add max-width for slick-list, otherwise the document
 * will be as wide as all carousel items next to each
 * other + all clones
 */

/* Adjust the .slick-track element to allow horizontal scrolling */
.slick-track {
    display: flex;
    width: auto !important; /* Important to override default width */
}

// Remove bottom margin
.slick-dotted.slick-slider {
    margin-bottom: 0;
}

// Dots positioning: padding of dots 5px out-of-box 15px => 20px
.slick-dots {
    bottom: 15px;

    li {
        width: 10px;
        margin: 0 4px;

        button {
            width: 10px;
        }
    }
}

// Dots style
.slick-dots li button::before {
    color: var(--color-blue-b100);
    opacity: 1;
    font-size: 10px;
    width: 10px;
}

// Active dot style
.slick-dots li.slick-active button::before {
    opacity: 1;
    color: var(--color-primary-red);
}
