@use "../../../abstracts/helpers" as *;

.author-box--header {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 11px;

    .author {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding: 11px 0;
        border-bottom: 1px solid var(--color-grey-g100);

        .avatar-container {
            display: flex;
            align-content: stretch;
            align-items: stretch;
            width: 32px;
            height: 32px;

            img {
                object-fit: cover;
                flex: 1;
                border-radius: 50%;
            }
        }

        .author-name {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.24px;

            a {
                font-weight: 700;
                color: var(--color-dark-d200);
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 0.96px;
            }
        }
    }

    .action-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0;
        border-bottom: 1px solid var(--color-grey-g100);

        .comment-link {
            font-size: 12px;
            letter-spacing: 0.96px;
            font-weight: 700;
            color: var(--color-dark-d200);
            text-transform: uppercase;
        }

        .social-media-links {
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;
            gap: 14px;
        }
    }
}

@include breakpoint(md, "only screen") {
    
    .author-box--header {
        flex-direction: row;
        gap: 10px;
        margin-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid var(--color-grey-g100);
    
        .author {
            padding: 0 0;
            border-bottom: none;
            border-right: 1px solid var(--color-grey-g100);

            .author-name {
                white-space: nowrap;
                padding-right: 10px;
            }
        }
    
        .action-bar {
            padding: 0 0;
            border-bottom: none;
            width: 100%;
        }
    }
}