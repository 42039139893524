@use "../../../../abstracts/helpers" as *;

@include breakpoint(tablet, "screen") {

    .content-section--homepage-2 {
        grid-template-areas:
            "row-1__landscape-banner row-1__landscape-banner row-1__landscape-banner"
            "row-2__main-news row-2__main-news row-2__news-2"
            "row-3__news-3--1 row-3__news-3--2 row-3__news-3--3"
            "row-4__kaufland-banners row-4__kaufland-banners row-4__kaufland-banners"
            "mod-hp-2-1__news-with-image-and-list mod-hp-2-1__news-with-image-and-list mod-hp-2-1__news-with-image-and-list"
            "mod-hp-2-2__deco-line mod-hp-2-2__deco-line mod-hp-2-2__deco-line"
            "mod-hp-2-3__portrait-banner--1 mod-hp-2-3__portrait-banner--2 mod-hp-2-3__portrait-banner--3"
            "mod-hp-2-4__news-with-image-and-list mod-hp-2-4__news-with-image-and-list mod-hp-2-4__news-with-image-and-list"
            "row-5__news-3--1 row-5__news-3--2 row-5__news-3--3"
            "row-6__story-module row-6__story-module row-6__story-module"
            "row-7__portrait-banner--1 row-7__portrait-banner--2 row-7__portrait-banner--3"
            "row-8__news-3--1 row-8__news-3--2 row-8__news-3--3"
            "row-9__column--1 row-9__column--2 row-9__column--3"
            "row-10__deco-line row-10__deco-line row-10__deco-line"
            "row-11__portrait-banner--1 row-11__portrait-banner--2 row-11__portrait-banner--3"
            "row-12__news-3--1 row-12__news-3--2 row-12__news-3--3"
            "row-13__kaufland-banners row-13__kaufland-banners row-13__kaufland-banners"
            "row-14__news-3--1 row-14__news-3--2 row-14__news-3--3"
            "row-15__portrait-banner--1 row-15__portrait-banner--2 row-15__portrait-banner--3"
            "row-16__categories-title-tag row-16__categories-title-tag row-16__categories-title-tag"
            "row-17__category-card--1 row-17__category-card--2 row-17__category-card--3"
            "row-18__category-card--1 row-18__category-card--2 row-18__category-card--3"
            "row-19__deco-line row-19__deco-line row-19__deco-line"
            "row-20__portrait-banner--1 row-20__portrait-banner--2 row-20__portrait-banner--3"
            "row-21__news-3--1 row-21__news-3--2 row-21__news-3--3";
    }
}

@include breakpoint(desktop, "screen") {

    .content-section--homepage-2 {
        grid-template-areas:
            "row-1__landscape-banner row-1__landscape-banner row-1__landscape-banner row-1__square-banner"
            "row-2__main-news row-2__main-news row-2__news-2 row-2__news-5"
            "row-3__news-3--1 row-3__news-3--2 row-3__news-3--3 row-3__news-4"
            "row-4__kaufland-banners row-4__kaufland-banners row-4__kaufland-banners row-4__kaufland-banners"
            "mod-hp-2-1__news-with-image-and-list mod-hp-2-1__news-with-image-and-list mod-hp-2-1__news-with-image-and-list mod-hp-2-1__news-with-image-and-list"
            "mod-hp-2-2__deco-line mod-hp-2-2__deco-line mod-hp-2-2__deco-line mod-hp-2-2__deco-line"
            "mod-hp-2-3__portrait-banner--1 mod-hp-2-3__portrait-banner--2 mod-hp-2-3__portrait-banner--3 mod-hp-2-3__portrait-banner--4"
            "mod-hp-2-4__news-with-image-and-list mod-hp-2-4__news-with-image-and-list mod-hp-2-4__news-with-image-and-list mod-hp-2-4__news-with-image-and-list"
            "row-5__news-3--1 row-5__news-3--2 row-5__news-3--3 sidebar-area"
            "row-6__story-module row-6__story-module row-6__story-module sidebar-area"
            "row-7__portrait-banner--1 row-7__portrait-banner--2 row-7__portrait-banner--3 sidebar-area"
            "row-8__news-3--1 row-8__news-3--2 row-8__news-3--3 sidebar-area"
            "row-9__column--1 row-9__column--2 row-9__column--3 sidebar-area"
            "row-10__deco-line row-10__deco-line row-10__deco-line sidebar-area"
            "row-11__portrait-banner--1 row-11__portrait-banner--2 row-11__portrait-banner--3 sidebar-area"
            "row-12__news-3--1 row-12__news-3--2 row-12__news-3--3 sidebar-area"
            "row-13__kaufland-banners row-13__kaufland-banners row-13__kaufland-banners sidebar-area"
            "row-14__news-3--1 row-14__news-3--2 row-14__news-3--3 sidebar-area"
            "row-15__portrait-banner--1 row-15__portrait-banner--2 row-15__portrait-banner--3 sidebar-area"
            "row-16__categories-title-tag row-16__categories-title-tag row-16__categories-title-tag sidebar-area"
            "row-17__category-card--1 row-17__category-card--2 row-17__category-card--3 sidebar-area"
            "row-18__category-card--1 row-18__category-card--2 row-18__category-card--3 sidebar-area"
            "row-19__deco-line row-19__deco-line row-19__deco-line sidebar-area"
            "row-20__portrait-banner--1 row-20__portrait-banner--2 row-20__portrait-banner--3 sidebar-area"
            "row-21__news-3--1 row-21__news-3--2 row-21__news-3--3 sidebar-area";
    }
}

@include ie-grid {

}