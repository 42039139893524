.aras-label {

    &--red {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        color: var(--color-primary-red);
        text-decoration: none;
        line-height: 24px;
    }

    &--white {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        color: var(--color-blue-b100);
        text-decoration: none;
        line-height: 24px;
    }

    &--underlined {
        text-decoration: underline;
    }
}