@use "../../../abstracts/helpers" as *;

.row-2__news-5 { display: none; }

.row-3__news-3--1,
.row-3__news-3--2,
.row-3__news-3--3 {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;

    .featured-article-container {
        display: none;
    }
}

.row-3__news-3--1 {
    margin-top: 20px;
}

.row-3__news-3--2,
.row-3__news-3--3 {
    .tag-container {
        display: none;
    }
}

.row-3__news-4 { display: none; }

.row-4__kaufland-banners {

    .kaufland-section__header {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.row-5__news-3--1,
.row-5__news-3--2,
.row-5__news-3--3,
.row-8__news-3--1,
.row-8__news-3--2,
.row-8__news-3--3 {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;

    .square-banner {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-grey-g100);
    }
}

.row-6__story-module {
    width: calc(100% - 20px);
    margin-left: 20px;

    .tag-container {
        margin-bottom: 22px;
    }
}

.row-7__portrait-banner--1,
.row-7__portrait-banner--2,
.row-7__portrait-banner--3 {
    display: none;
}

.row-8__news-3--1,
.row-8__news-3--2,
.row-8__news-3--3 {
    .columns-on-mobile {
        margin-top: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-grey-g100);
        margin-bottom: 20px;
    }
}

.row-9__column--1,
.row-9__column--2,
.row-9__column--3 {
    display: none;
}

.row-1__landscape-banner,
.row-1__square-banner,
.row-2__main-news,
.row-2__news-2,
.row-2__news-5,
.row-3__news-4,
.row-10__deco-line,
.row-11__portrait-banner--1,
.row-11__portrait-banner--2,
.row-11__portrait-banner--3,
.row-12__news-3--1,
.row-12__news-3--2,
.row-12__news-3--3,
.row-13__kaufland-banners,
.row-14__news-3--1,
.row-14__news-3--2,
.row-14__news-3--3,
.row-15__portrait-banner--1,
.row-15__portrait-banner--2,
.row-15__portrait-banner--3,
.row-16__categories-title-tag,
.row-17__category-card--1,
.row-17__category-card--2,
.row-17__category-card--3,
.row-18__category-card--1,
.row-18__category-card--2,
.row-18__category-card--3,
.row-19__deco-line,
.row-20__portrait-banner--1,
.row-20__portrait-banner--2,
.row-20__portrait-banner--3,
.row-21__news-3--1,
.row-21__news-3--2,
.row-21__news-3--3,
.sidebar-area {
    display: none;
}

@include breakpoint(tablet, "screen") {

    /*
     * Assign the elements to grid areas
     */
    .row-3__news-3--1 { grid-area: row-3__news-3--1; }
    .row-3__news-3--2 { grid-area: row-3__news-3--2; }
    .row-3__news-3--3 { grid-area: row-3__news-3--3; }

    .row-5__news-3--1 { grid-area: row-5__news-3--1; }
    .row-5__news-3--2 { grid-area: row-5__news-3--2; }
    .row-5__news-3--3 { grid-area: row-5__news-3--3; }

    .row-7__portrait-banner--1 { grid-area: row-7__portrait-banner--1; }
    .row-7__portrait-banner--2 { grid-area: row-7__portrait-banner--2; }
    .row-7__portrait-banner--3 { grid-area: row-7__portrait-banner--3; }
    
    .row-8__news-3--1 { grid-area: row-8__news-3--1; }
    .row-8__news-3--2 { grid-area: row-8__news-3--2; }
    .row-8__news-3--3 { grid-area: row-8__news-3--3; }

    .row-9__column--1 {
        display: block;
        grid-area: row-9__column--1;
        margin-top: 10px;
    }
    .row-9__column--2 {
        display: block;
        grid-area: row-9__column--2;
        margin-top: 10px;
    }
    .row-9__column--3 {
        display: block;
        grid-area: row-9__column--3;
        margin-top: 10px;
    }

    .row-10__deco-line { grid-area: row-10__deco-line; }

    .row-11__portrait-banner--1 { grid-area: row-11__portrait-banner--1; }
    .row-11__portrait-banner--2 { grid-area: row-11__portrait-banner--2; }
    .row-11__portrait-banner--3 { grid-area: row-11__portrait-banner--3; }

    .row-12__news-3--1 { grid-area: row-12__news-3--1; }
    .row-12__news-3--2 { grid-area: row-12__news-3--2; }
    .row-12__news-3--3 { grid-area: row-12__news-3--3; }

    .row-13__kaufland-banners { grid-area: row-13__kaufland-banners; }

    .row-14__news-3--1 { grid-area: row-14__news-3--1; }
    .row-14__news-3--2 { grid-area: row-14__news-3--2; }
    .row-14__news-3--3 { grid-area: row-14__news-3--3; }

    .row-15__portrait-banner--1 { grid-area: row-15__portrait-banner--1; }
    .row-15__portrait-banner--2 { grid-area: row-15__portrait-banner--2; }
    .row-15__portrait-banner--3 { grid-area: row-15__portrait-banner--3; }

    .row-16__categories-title-tag { grid-area: row-16__categories-title-tag; }

    .row-17__category-card--1 { grid-area: row-17__category-card--1; }
    .row-17__category-card--2 { grid-area: row-17__category-card--2; }
    .row-17__category-card--3 { grid-area: row-17__category-card--3; }

    .row-18__category-card--1 { grid-area: row-18__category-card--1; }
    .row-18__category-card--2 { grid-area: row-18__category-card--2; }
    .row-18__category-card--3 { grid-area: row-18__category-card--3; }

    .row-19__deco-line { grid-area: row-19__deco-line; }

    .row-20__portrait-banner--1 { grid-area: row-20__portrait-banner--1; }
    .row-20__portrait-banner--2 { grid-area: row-20__portrait-banner--2; }
    .row-20__portrait-banner--3 { grid-area: row-20__portrait-banner--3; }

    .row-21__news-3--1 {
        grid-area: row-21__news-3--1;
        margin-bottom: 55px;
    }
    .row-21__news-3--2 {
        grid-area: row-21__news-3--2;
        margin-bottom: 55px;
    }
    .row-21__news-3--3 {
        grid-area: row-21__news-3--3;
        margin-bottom: 55px;
    }

    /**
     * Grid area-specific styles
     */

    .row-1__landscape-banner {
        display: block;
        grid-area: row-1__landscape-banner;
        margin-bottom: 16px;

        a {
            display: flex;
            align-content: stretch;
            align-items: stretch;
            
            img {
                object-fit: cover;
                flex: 1;
                transition: var(--base-transition);
            }

            &:hover {
                @include image-hover;
            }
        }
    }

    .row-2__main-news {
        grid-area: row-2__main-news; 
        display: block;

        .main-news-box.main-news-box--content-under-image {
            .content {
                margin-bottom: 0;
            }
        }
    }

    .row-2__news-2 {
        grid-area: row-2__news-2;
        display: flex;
        flex-direction: column;

        .main-article {
            flex-grow: 1;
            flex-shrink: 1;
            height: auto;
        }

        .article-list {
            border: 1px solid var(--color-grey-g100);
            border-bottom: none;
            border-top: none;

            .article-list__item:last-of-type {
                border-bottom: 1px solid var(--color-grey-g100);
            }

            .tags-header {
                flex-direction: row;
                gap: 0;

                .aras-tag:not(:last-of-type) {
                    margin-right: 10px;
                  }

                .aras-label--red {
                    display: inline;
                    line-height: 1.2;
                }
            }
        }
    }

    .row-3__news-3--1,
    .row-3__news-3--2,
    .row-3__news-3--3 {
        width: 100%;
        margin-left: unset;
        margin-right: unset;

        .featured-article-container {
            display: flex;
        }
    }

    .row-3__news-3--1 {
        margin-top: unset;
    }

    .row-3__news-3--2,
    .row-3__news-3--3 {
        .tag-container {
            display: block;
        }
    }

    .row-4__kaufland-banners {
        grid-area: row-4__kaufland-banners;

        .kaufland-section__header {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .row-5__news-3--1,
    .row-5__news-3--2,
    .row-5__news-3--3,
    .row-8__news-3--1,
    .row-8__news-3--2,
    .row-8__news-3--3 {
        width: 100%;
        margin-left: unset;
        margin-right: unset;

        .square-banner {
            display: none;
        }

        .only-mobile {
            display: none;
        }
    }

    .row-5__news-3--1 .featured-article-container .featured-article:nth-of-type(2),
    .row-5__news-3--2 .featured-article-container .featured-article:nth-of-type(2),
    .row-5__news-3--3 .featured-article-container .featured-article:nth-of-type(2) {
        margin-bottom: 0;
    }

    .row-6__story-module {
        grid-area: row-6__story-module;
        width: 100%;
        margin-left: unset;
    }

    .row-9__column--1,
    .row-9__column--2,
    .row-9__column--3 {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
        padding-bottom: 0;
        border-bottom: none;
    }

    .row-12__news-3--1,
    .row-12__news-3--2,
    .row-12__news-3--3,
    .row-14__news-3--1,
    .row-14__news-3--2,
    .row-14__news-3--3,
    .row-21__news-3--1,
    .row-21__news-3--2,
    .row-21__news-3--3 {
        display: flex;
        flex-direction: column;
    }

    .row-7__portrait-banner--1,
    .row-7__portrait-banner--2,
    .row-7__portrait-banner--3,
    .row-10__deco-line,
    .row-11__portrait-banner--1,
    .row-11__portrait-banner--2,
    .row-11__portrait-banner--3,
    .row-13__kaufland-banners,
    .row-15__portrait-banner--1,
    .row-15__portrait-banner--2,
    .row-15__portrait-banner--3,
    .row-16__categories-title-tag,
    .row-17__category-card--1,
    .row-17__category-card--2,
    .row-17__category-card--3,
    .row-18__category-card--1,
    .row-18__category-card--2,
    .row-18__category-card--3,
    .row-19__deco-line,
    .row-20__portrait-banner--1,
    .row-20__portrait-banner--2,
    .row-20__portrait-banner--3 {
        display: block;
    }

    .row-3__news-3--1 .news-3-big-article p,
    .row-3__news-3--2 .news-3-big-article p,
    .row-3__news-3--3 .news-3-big-article p,
    .row-5__news-3--1 .news-3-big-article p,
    .row-5__news-3--2 .news-3-big-article p,
    .row-5__news-3--3 .news-3-big-article p,
    .row-8__news-3--1 .news-3-big-article p,
    .row-8__news-3--2 .news-3-big-article p,
    .row-8__news-3--3 .news-3-big-article p,
    .row-12__news-3--1 .news-3-big-article p,
    .row-12__news-3--2 .news-3-big-article p,
    .row-12__news-3--3 .news-3-big-article p,
    .row-14__news-3--1 .news-3-big-article p,
    .row-14__news-3--2 .news-3-big-article p,
    .row-14__news-3--3 .news-3-big-article p,
    .row-21__news-3--1 .news-3-big-article p,
    .row-21__news-3--2 .news-3-big-article p,
    .row-21__news-3--3 .news-3-big-article p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: pre-wrap;
    }

    .row-3__news-3--1 .featured-article-container .featured-article h3,
    .row-3__news-3--2 .featured-article-container .featured-article h3,
    .row-3__news-3--3 .featured-article-container .featured-article h3,
    .row-5__news-3--1 .featured-article-container .featured-article h3,
    .row-5__news-3--2 .featured-article-container .featured-article h3,
    .row-5__news-3--3 .featured-article-container .featured-article h3,
    .row-8__news-3--1 .featured-article-container .featured-article h3,
    .row-8__news-3--2 .featured-article-container .featured-article h3,
    .row-8__news-3--3 .featured-article-container .featured-article h3,
    .row-12__news-3--1 .featured-article-container .featured-article h3,
    .row-12__news-3--2 .featured-article-container .featured-article h3,
    .row-12__news-3--3 .featured-article-container .featured-article h3,
    .row-14__news-3--1 .featured-article-container .featured-article h3,
    .row-14__news-3--2 .featured-article-container .featured-article h3,
    .row-14__news-3--3 .featured-article-container .featured-article h3,
    .row-21__news-3--1 .featured-article-container .featured-article h3,
    .row-21__news-3--2 .featured-article-container .featured-article h3,
    .row-21__news-3--3 .featured-article-container .featured-article h3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: pre-wrap;
    }
}

@include breakpoint(desktop, "screen") {

    .row-1__landscape-banner {
        a {
            min-height: 250px;
            max-height: 250px;
        }
    }

    .row-1__square-banner {
        grid-area: row-1__square-banner;
        display: flex;
        
        a {
            min-height: 250px;
            max-height: 250px;
        }
    }
    
    .row-2__news-5 {
        grid-area: row-2__news-5;
    }

    .row-2__main-news,
    .row-2__news-2,
    .row-2__news-5 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .row-2__news-2 {
        .article-list {
            .article-list__item {
                h3 {
                    font-size: 1.5rem;
                    font-size: clamp(1.5rem, 0.5657894736842106rem + 1.3157894736842104vw, 1.75rem);
                }
            }
        }
    }

    .row-2__main-news {
        .main-news-box.main-news-box--content-under-image {
            .content {
                margin-bottom: 0;
            }
        }
    }

    .row-2__news-5 {
        .aras-btn {
            align-self: flex-end;
            margin-top: auto;
        }
    }
    .row-3__news-4 {
        display: flex;
        grid-area: row-3__news-4;
        padding-top: 48px;

        .news-4-article {
            max-height: 488px;
        }
    }

    .sidebar-area {
        display: flex;
        flex-direction: column;
        grid-area: sidebar-area;
        max-width: 100%;
        padding-top: 41px;
        gap: 32px;

        .news-4-article {
            height: 488px;

            .overlay-helper {
                width: auto;
            }
        }
    }
}