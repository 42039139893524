@use "../abstracts/helpers" as *;

/**
 * This file contains the module modifications
 * for single articles
 */

.content-section--single-article {
    
    .main-article {
        width: 100%;
        max-width: 100%;

        .main-news-box {
            width: 100%;

            .header-gallery-container,
            .featured-image-container {
                min-width: 335px;
                width: calc(100vw - 40px);
                min-height: clamp(200px, 58.7vw, 587px);
                height: clamp(200px, 58.7vw, 587px);
            }
        }
    }

    .featured-article-container,
    .featured-article-container.featured-article-container--small-to-big {
        width: 100%;
        max-width: 100%;
    }
}

@include breakpoint(sm, "only screen") {
    
    .content-section--single-article {
        
        .featured-article--small-to-big {
            flex-direction: row;
            width: 100%;
            max-width: 100%;
            padding-bottom: 12px;
            border-bottom: 1px solid var(--color-grey-g100);
            margin-bottom: 0;
            gap: 20px;
            
            header {
                position: relative;
                width: 300px;
                height: 178px;
                overflow: hidden;
                // For image cover
                display: flex;
                align-content: stretch;
                align-items: stretch;
                
                img {
                    object-fit: cover;
                    flex: 1;
                }
            }

            p {
                display: none;
            }
        }
    }
}

@include breakpoint(md, "screen") {
    
    .content-section--single-article {

        .featured-article {
            max-width: 300px;
        }

        .featured-article--small-to-big {
            flex-direction: column;
            gap: 0;

            p {
                display: block;
            }
        }

    }
}

@include breakpoint(md, "screen") {
    
    .content-section--single-article {

        .container__row.article-content-container {
            flex-wrap: nowrap;

            .main-article {
                max-width: 632px;

                .main-news-box {
                    .header-gallery-container,
                    .featured-image-container {
                        width: 632px;
                        height: 414px;
                    }
                }
            }

            .main-sidebar.gap-16 {
                width: 300px;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
            }
        }
    }
}