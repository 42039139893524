@use '../../abstracts/helpers' as *;

.main-navbar__search {
    display: none;
}

// 64rem ~1024px
@include breakpoint(md, "screen") {
    .main-navbar__search {
        display: block;
    }

    .main-navbar__search-form {
        height: 18px;
        line-height: normal;
    }

    .main-navbar__search__field {
        border: none;
        border-radius: 17px;
        height: 18px;
        // BG padding from left side (9px) + BG image (18px) + BG padding from left side (9px) for symmetric
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19 19" fill="none"><circle cx="8" cy="8" r="7" stroke="%23ECF0F1" stroke-width="2"/><path d="M12.5 12.5L18 18" stroke="%23ECF0F1" stroke-width="2"/></svg>');
        padding-left: 36px;
        background-color: transparent;
        background-attachment: scroll;
        background-size: 18px 18px;
        background-position: left 9px center;
        background-repeat: no-repeat;
        position: relative;
        width: 0;
        cursor: pointer;
        transition: width 400ms ease, background 400ms ease;
    
        &:focus {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none"><circle cx="8" cy="8" r="7" stroke="%23969696" stroke-width="2"/><path d="M12.5 12.5L18 18" stroke="%23969696" stroke-width="2"/></svg>');
            background-color: var(--color-blue-b100);
            cursor: text;
            outline: 0;
            // Height is the same as the off-canvas search input...
            height: 34px;
            // But drag it to top for the vertical align
            position: relative;
            top: -8px; // (37 / 2) / 2 = 8.5 ~ 8px
            width: 335px;
            color: var(--color-dark-d200);
        }
    }
    
    .main-navbar__search-submit {
        display: none;
    }
}