@use "../../abstracts/helpers" as *;

.category-card-container {
    border-bottom: 1px solid var(--color-grey-g100);
}

.category-card-container--without-border {
    border-bottom: none;
}

.category-card {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    // For image cover
    display: flex;
    align-items: stretch;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--gradient-small-blue);
        opacity: 0.85;
    }

    img {
        object-fit: cover;
        flex: 1;
        position: relative;
        transition: var(--base-transition);
    }

    h3 {
        position: absolute;
        left: 20px;
        bottom: 12px;
        z-index: 1;
        font-family: var(--ff-condensed);
        font-size: 28px;
        font-weight: 600;
        line-height: 30px;
        color: var(--color-blue-b100);
        text-decoration: none;
        transition: var(--base-transition);
    }

    &:hover {
        
        @include image-hover;

        h3 {
            color: var(--color-link-hover);
        }
    }
}

// on 1296px align the cards to the left side
@include breakpoint(lg, "screen") {
    
    .category-card {

        h3 {
            bottom: 16px;
        }
    }
}
