/**
 * Tag + Label (Main News)
 */
.article-meta-container {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
