@use '../../abstracts/helpers' as *;

body {
    background-color: var(--color-blue-b100);
    color: var(--color-dark-d200);
    font-family: var(--ff-regular);
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.container--fluid {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

// Define as flexbox, because the change of children's order on mobile / desktop
.main-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // Center the direct childen because .header-brand-nav-container and .header-hero-section
    // when screen width > 1440px
    justify-content: center;
    gap: 0;
}

.content-section {
    background-color: var(--color-blue-b100);
}

.main-footer {
    background-color: var(--color-dark-d200);
    padding: 14px 20px 15px 20px;

    &__inner-container {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }
}

.content-section {
    margin: 0 0;
}

// 50.75rem ~812px
@include breakpoint(tablet, "screen") {

    .main-footer {
        padding: 28px 0 30px 0;
    
        &__inner-container {
            padding: 0 32px;
            justify-content: space-between;
            height: 18px;
        }
    }
}

// 64rem ~1024px
@include breakpoint(md, "screen") {

    .main-header {
        justify-content: space-between;
    }
}

// 81rem ~1296px
@include breakpoint(lg, "screen") {

    .main-header {
        max-width: 100%;
    }

    .main-footer {
        max-width: 100%;
        
        &__inner-container {
            padding: 0 72px;
        }
    }
}

@include breakpoint(xl, "screen") {

    .main-header {

        .main-navbar__inner-container {
            padding: 28px 0 30px 0;
            max-width: 1296px;
            margin-left: auto;
            margin-right: auto;
        }

        .desktop-hero-section {
            padding: 32px 0 32px 0;
            max-width: 1296px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .content-section {
        padding: 0 0;
        max-width: 1296px;
        margin-left: auto;
        margin-right: auto;
    }

    .main-footer {

        &__inner-container {
            padding: 0 0;
            max-width: 1296px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
