@use "../../../abstracts/helpers" as *;

.author-box--footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    border-top: 1px solid var(--color-grey-g100);
    border-bottom: 1px solid var(--color-grey-g100);

    .share-link {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;
        line-height: normal;

        a {
            font-weight: 700;
            color: var(--color-dark-d200);
            text-transform: uppercase;
            text-decoration: underline;
            letter-spacing: 0.96px;
        }
    }

    .social-media-links {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        gap: 14px;

        svg {
            width: 27px;
            height: 26px;
        }
    }
}

@include breakpoint(md, "only screen") {

    .author-box--footer {
        padding-top: 2px;
    }

    .author-box--footer {
        border-top: none;
    }
}