.story-container {
    display: flex;
    width: 100%;
    max-width: 1440px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--color-grey-g100);
    justify-content: left;
    align-items: flex-start;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .story-card {
        width: 188px;
        flex: 0 0 188px;
        margin-right: 20px !important;

        &__image-container {
            width: 188px;
            height: 351px;
            display: flex;
            align-content: stretch;
            align-items: stretch;
            overflow: hidden;
            margin-bottom: 12px;
        
            img {
                object-fit: cover;
                flex: 1;
                border-radius: 12px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            
            a {
                color: var(--color-dark-d200);
                text-decoration: none;

                &:hover {
                    color: var(--color-link-hover);
                }
            }
        }

        .bottom-meta {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            color: var(--color-grey-g200);

            .story-views-count {
                letter-spacing: 0.72px;
            }

            .story-views-label {
                letter-spacing: 0.24px;
            }
        }
    }
}