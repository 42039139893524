@use "../../abstracts/helpers" as *;

.desktop-hero-section {
    flex: 0 0 100%;
    order: 2;
    padding: 20px 0;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    border-bottom: 1px solid var(--color-grey-g100);

    .logo-container--desktop {
        display: none;
    }

    img {
        margin-left: auto;
        margin-right: auto;
        min-height: 56px;
    }
}

// 64rem ~1024px
@include breakpoint(tablet, "screen") {
    .desktop-hero-section {
        flex: 0 0 100%;
        max-width: 1440px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 32px 32px 32px 32px;
        margin-left: auto;
        margin-right: 0;
        border-bottom: 0;
        margin-bottom: 10px;
        gap: 17.63889%;

        img {
            margin-left: auto;
            margin-right: 0;
        }

        .logo-container--desktop {
            display: block;
        }
    }
}

// 90rem ~1440px
@include breakpoint(xl, "screen") {
    
    .desktop-hero-section {
        padding: 32px 72px 32px 72px;
        margin-left: 0;
    }
}