@use "../../../abstracts/helpers" as *;

// Add bottom padding and border for the
// article's container element
.news-4-article-helper {
    //width: 375px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-grey-g100);
}

.news-4-article {
    //width: 375px;
    width: 100%;
    height: 286px;
    overflow: hidden;
    // For image cover
    display: flex;
    align-content: stretch;
    align-items: stretch;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--gradient-landscape);
    }

    img {
        object-fit: cover;
        flex: 1;
        position: relative;
        transition: var(--base-transition);
    }

    .overlay-helper {
        position: absolute;
        bottom: 18px;
        left: 20px;
        right: 20px;
        width: calc(100% - 40px);
        // Show it on the top of gradient
        z-index: 1;

        .tags-header {
            margin-bottom: 7px;
        }

        a {
            text-decoration: none;
            color: var(--color-blue-b100);
            transition: var(--base-transition);
        }

        & > a {
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.24px;
            margin-top: 12px;

            span {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.96px;
            }
        }
    }

    &:hover {

        @include image-hover;

        .overlay-helper {

            a:hover {
                color: var(--color-link-hover);
            }
        }
    }
}

@include breakpoint(tablet, "screen") {
    .news-4-article {
        height: 100%;
    }
}

@include breakpoint(desktop, "screen") {

    .news-4-article {
        .overlay-helper {
            width: 260px;
        }
    }
}

@include breakpoint(lg, "screen") {

    .news-4-article-helper {
        width: 100%;
        padding-bottom: 0;
        border-bottom: none;
        margin-left: 0;
        margin-right: 0;
    }
}